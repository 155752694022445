module.exports = [
  {
    id: 1,
    name: "Hamano",
    name_lower: "hamano",
    genre: ["skintype"],
    title: {
      ja: "テティス横濱美容皮膚科 院長",
      en: "Director of TETIS Yokohama Dermatology Clinic",
      zh: "テティス横滨美容皮肤科诊所院长",
    },
    short_profile: {
      ja: "テティス横濱美容皮膚科<br>院長&nbsp;日本スキンケア協会・皮膚科顧問<br>2015ミス・ユニバース&nbsp;ビューティーキャンプ講師…",
      en: "Director of TETIS Yokohama Dermatology Clinic<br>Advisor to the Japan Skin Care Association and Dermatology<br>2015 Miss Universe Beauty Camp Lecturer...",
      zh: "テティス横滨美容皮肤科诊所院长<br>日本护肤协会皮肤科顾问<br>2015年环球小姐美容讲师...",
    },
  },
  {
    id: 8,
    name: "かずのすけ",
    name_lower: "kazunosuke",
    genre: ["skincare"],
    title: {
      ja: "美容を教える化学の先生",
      en: "Chemistry teacher who teaches beauty",
      zh: "教授美容化学的老师",
    },
    mid_profile: {
      ja: "<p>アトピーで肌荒れに悩んだ経験から美容や化粧品に関心を持ち、大学院等で化粧品や界面活性剤について専門で研究。</p><p>2013年頃より各種SNSにて化粧品の成分解析や様々な美容情報の発信を開始。アメブロ月間最高500万PV、Twitterフォロワー20万人、YouTube登録者28万人。「美容化学者」として美容関連著書を10冊以上執筆し、敏感肌向けオリジナル化粧品「CeraLabo」の製品設計＆企画開発も手掛ける。</p><br><p>最終学歴：横浜国立大学院卒・環境学修士　※博士課程後期中退化粧品企画開発・広告指導顧問　株式会社セララボ　代表取締役</p>",
      en: '<p>Interested in beauty and cosmetics from the experience of suffering from atopy and skin roughness, and specialized in cosmetics and surfactants at graduate school, etc.</p><p>Since around 2013, he has started to disseminate cosmetic ingredient analysis and various beauty information on various SNS. Monthly highest 5 million PV on Ameba blog, 200,000 Twitter followers, 280,000 YouTube subscribers. As a "beauty chemist", he has written more than 10 books on beauty-related books, and has also been involved in the product design and planning and development of original cosmetics for sensitive skin "CeraLabo".</p><br><p>Final academic background: Graduated from Yokohama National University Graduate School, Master of Environmental Studies * Doctoral program dropout Cosmetics planning and development, advertising guidance advisor Representative Director, CeraLabo Co., Ltd.</p>',
      zh: "<p>从患有皮肤炎和皮肤粗糙的经历中对美容和化妆品产生了兴趣，并在研究生院等专门研究化妆品和表面活性剂。</p><p>从2013年开始，他开始在各种SNS上传播化妆品成分分析和各种美容信息。 Ameba博客每月最高500万PV，Twitter粉丝20万人，YouTube订阅者28万人。作为“美容化学家”，他已经写了10多本与美容相关的书，还参与了敏感肌肤原装化妆品“ CeraLabo”的产品设计和规划开发。</p><br><p>最终学术背景：横滨国立大学研究生院毕业，环境研究硕士*博士课程辍学化妆品规划和开发，广告指导顾问代表董事，CeraLabo有限公司</p>",
    },
    short_profile: {
      ja: "アトピーで肌荒れに悩んだ経験から美容や化粧品に関心を持ち、大学院等で化粧品や界面活性剤について専門で研究。2013年頃より各種SNSにて化粧品の成分解析や様々な美容情報の発信を開始。",
      en: "Interested in beauty and cosmetics from the experience of suffering from atopy and skin roughness, and specialized in cosmetics and surfactants at graduate school, etc. Since around 2013, he has started to disseminate cosmetic ingredient analysis and various beauty information on various SNS.",
      zh: "从患有皮肤炎和皮肤粗糙的经历中对美容和化妆品产生了兴趣，并在研究生院等专门研究化妆品和表面活性剂。从2013年开始，他开始在各种SNS上传播化妆品成分分析和各种美容信息。",
    },
  },
  {
    id: 3,
    name: "MANAMI",
    name_lower: "manami",
    genre: ["skincare"],
    title: {
      ja: "トータルビジュアルプロデューサー",
      en: "Total Visual Producer",
      zh: "全面视觉制作人",
    },
    mid_profile: {
      ja: '<div class="profile_content"><p>海外を中心に、15歳からモデル業を経験。</p><p>その後、20代後半でヘアメイクアップアーティストへ転身し、帰国後は「トータルビジュアルプロデューサー」として活動。</p><p>芸能人、政治家、ミスコン出場者を含めた顧客に対し、メイクから表情・所作まで「見た目に関するすべてのこと」をプロデュースしている。</p><p>テレビや雑誌など多方面で活躍する傍ら、法律を学ぶため、大学にも在籍。</p></div>',
      en: '<div class="profile_content"><p>Experienced modeling business from the age of 15, mainly overseas.</p><p>After that, she changed her career to a hair and makeup artist in her late twenties, and after returning to Japan, she has been active as a "total visual producer".</p><p>She produces "everything about appearance" from makeup to facial expressions and movements for customers including entertainers, politicians, and Miss Contest contestants.</p><p>While active in various fields such as TV and magazines, she is also enrolled in university to study law.</p></div>',
      zh: '<div class="profile_content"><p>从15岁开始，主要在海外从事模特业务。</p><p>此后，她在20多岁时改变了职业，成为一名发型和化妆师，回到日本后，她一直活跃在“全面视觉制作人”中。</p><p>她为包括演艺人员，政治家和小姐竞赛选手在内的客户制作“关于外观的一切”，从化妆到面部表情和动作。</p><p>在电视和杂志等各个领域活跃的同时，她还在大学就读，学习法律。</p></div>',
    },
    short_profile: {
      ja: "トータルビジュアルプロデューサー。海外でのモデル経験を活かし、日本に帰国後はヘアメイク、ファッション、歩き方など「見た目に関するすべてのプロデ…",
      en: "Total Visual Producer. After returning to Japan, she has been active as a hair and makeup artist, fashion, walking, etc. using her experience as a model overseas.",
      zh: "全面视觉制作人。在日本回国后，她一直活跃在发型和化妆师，时尚，步行等领域，利用她在海外的模特经验。",
    },
  },
  {
    id: 4,
    name: "Eri",
    name_lower: "eri",
    genre: ["skincare", "makeup"],
    title: {
      ja: "プロメイクアップアーティスト",
      en: "Professional Makeup Artist",
      zh: "专业化妆师",
    },
    mid_profile: {
      ja: '<div class="profile_content"><p>18歳で渡米し、カリフォルニア州の美容師免許を取得。</p><p>アメリカで美容師を経験し、帰国後はヘアメイクへ転身。</p><p>ヘアメイクスタジオ「Looper」を設立し、パリコレのヘアメイクチームにも参加。</p><p>現在はサロン経営の傍ら、CMやドラマの撮影現場にてタレント等のヘアメイクを担当し、ヘアショーの主催や、美容学校での授業も受け持つなど、幅広い活動を行っている。</p></div>',
      en: '<div class="profile_content"><p>At the age of 18, she went to the United States and obtained a California beautician license.</p><p>After experiencing a beautician in the United States, she changed her career to a hair and makeup artist after returning to Japan.</p><p>She established the hair and makeup studio "Looper" and also participated in the hair and makeup team of Paris Collection.</p><p>Currently, while managing the salon, she is in charge of hair and makeup for talents at the shooting site of CM and dramas, and is engaged in a wide range of activities such as organizing hair shows and teaching at beauty schools.</p></div>',
      zh: '<div class="profile_content"><p>18岁时，她前往美国并获得了加利福尼亚美容师执照。</p><p>在美国体验了美容师之后，她回到日本后改变了职业，成为一名发型和化妆师。</p><p>她成立了发型和化妆工作室“Looper”，并参加了巴黎时装秀的发型和化妆团队。</p><p>目前，她在管理沙龙的同时，还在CM和戏剧的拍摄现场为人才负责发型和化妆，并从事各种活动，例如组织发型秀和在美容学校教学。</p></div>',
    },
    short_profile: {
      ja: "カリフォルニア州で美容師免許を取得後、美容院に勤務。日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを…",
      en: "After obtaining a beautician license in California, she worked at a beauty salon. After returning to Japan, she changed her career to a hair and makeup artist, and is currently engaged in hair and makeup in the advertising and fashion industry while managing the salon.",
      zh: "在加利福尼亚州获得美容师执照后，她在美容院工作。回到日本后，她改变了职业，成为一名发型和化妆师，目前在管理沙龙的同时，还在广告和时尚行业从事发型和化妆。",
    },
  },
  {
    id: 5,
    name: "Ayako",
    name_lower: "ayako",
    genre: ["skincare"],
    title: { ja: "", en: "", zh: "" },
    mid_profile: {
      ja: '<div class="profile_content"><p>スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。</p><p>その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100以上のアイテムを世に送り出してきた。</p><p>2019年4月に独立後、10年に渡る美容業界の経験を活かし、新規コスメブランドのプロデュースやサポートを行っている。</p><div>',
      en: '<div class="profile_content"><p>After working as a salesperson and product planner for a cosmetics brand mainly for skincare products.</p><p>After that, through the operation of a major esthetic company and a perfume manufacturer, more than 100 items have been sent out to the world.</p><p>After becoming independent in April 2019, she has been producing and supporting new cosmetics brands based on her 10 years of experience in the beauty industry.</p><div>',
      zh: '<div class="profile_content"><p>在主要从事护肤产品的化妆品品牌担任销售人员和产品策划人员后。</p><p>此后，通过一家大型美容公司和香水制造商的运营，已将100多件物品送到了世界各地。</p><p>在2019年4月独立后，她根据自己在美容行业10年的经验，一直在生产和支持新的化妆品品牌。</p><div>',
    },
    short_profile: {
      ja: "スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100…",
      en: "After working as a salesperson and product planner for a cosmetics brand mainly for skincare products. After that, through the operation of a major esthetic company and a perfume manufacturer, more than 100 items have been sent out to the world.",
      zh: "在主要从事护肤产品的化妆品品牌担任销售人员和产品策划人员后。此后，通过一家大型美容公司和香水制造商的运营，已将100多件物品送到了世界各地。",
    },
  },
  {
    id: 2,
    name: "Maya",
    name_lower: "maya",
    genre: ["skincare"],
    title: { ja: "", en: "", zh: "" },
    mid_profile: {
      ja: '<div class="profile_content"><p>栄養士の専門学校を卒業後、日本・韓国のエステティシャンや美容部員に従事。</p><p>ミスインターナショナル、ミスユニバース、ミススプラナショナルなど、数々の大会に出場し、ワンホンアジアスーパーチャレンジでは世界5位に。</p><p>現在は豊富なコスメの知識を活かし、株式会社JustFit創業メンバー、および YUNI COSME コンシェルジュとして活動している。</p></div>',
      en: '<div class="profile_content"><p>After graduating from a nutritionist vocational school, she worked as an esthetician and beauty clerk in Japan and Korea.</p><p>She has participated in numerous competitions such as Miss International, Miss Universe, and Miss Supra National, and was ranked 5th in the world in the One Hong Asia Super Challenge.</p><p>She is currently using her extensive knowledge of cosmetics to work as a founding member of JustFit Co., Ltd. and as a YUNI COSME concierge.</p></div>',
      zh: '<div class="profile_content"><p>在营养师职业学校毕业后，她在日本和韩国担任美容师和美容店员。</p><p>她参加了许多比赛，例如国际小姐，环球小姐和超级国家小姐，获得了香港亚洲超级挑战赛世界第五名。</p><p>她目前正在利用自己对化妆品的广泛知识，担任JustFit有限公司的创始成员和YUNI COSME接待员。</p></div>',
    },
    short_profile: {
      ja: "ミスインターナショナル、ミスユニバース、ミススプラナショナル、ミス日本etc...など日本大会出場。ワンホンアジアスーパーチャレンジ&nbsp;世界大会5位…",
      en: "Miss International, Miss Universe, Miss Supra National, Miss Japan, etc. participated in the Japanese competition. One Hong Asia Super Challenge World Competition 5th...",
      zh: "国际小姐，环球小姐，超级国家小姐，日本小姐等参加了日本比赛。香港亚洲超级挑战赛世界比赛第五名...",
    },
  },
  {
    id: 6,
    name: "nico",
    name_lower: "nico",
    genre: ["skincare"],
    title: { ja: "", en: "", zh: "" },
    mid_profile: {
      ja: '<div class="profile_content"><p>外資系化粧品メーカーでの美容部員、ブランドトレーナー、セラピストなど、30年近くに渡り美容業界を経験。</p><p>自身の肌荒れや不妊治療などの体験から、現在は「肌とカラダとココロを活かすナチュラルなトータルセルフケア」をポリシーとしたサロンを経営。</p><p>フェイシャルリフレクソロジーやオーガニック・ナチュラルコスメを中心に、トータルセルフケアのアドバイスを行っている。</p><div>',
      en: '<div class="profile_content"><p>Over the past 30 years, she has worked in the beauty industry as a beauty clerk, brand trainer, therapist, etc. at a foreign-affiliated cosmetics manufacturer.</p><p>Based on her own experience of skin roughness and infertility treatment, she now runs a salon with the policy of "natural total self-care that makes the skin, body and mind active".</p><p>She advises on total self-care, focusing on facial reflexology and organic/natural cosmetics.</p><div>',
      zh: '<div class="profile_content"><p>在过去的30年中，她在外国化妆品制造商担任美容店员，品牌培训师，治疗师等，从事美容行业。</p><p>基于她自己的皮肤粗糙和不孕治疗经验，她现在经营着一家沙龙，其政策是“使皮肤，身体和心灵活跃的自然全面自我护理”。</p><p>她在面部反射疗法和有机/天然化妆品方面提供建议，重点是全面自我护理。</p><div>',
    },
    short_profile: {
      ja: "外資系化粧品メーカーでの美容部員、メイクアップアーティスト、ブランドトレーナー、コスメ専門ECサイトバイヤー等25年以上に渡る化粧品業界での経験と…",
      en: "Beauty clerk, makeup artist, brand trainer, cosmetics specialized EC site buyer, etc. at a foreign-affiliated cosmetics manufacturer. Based on her experience in the cosmetics industry for over 25 years...",
      zh: "在外国化妆品制造商担任美容店员，化妆师，品牌培训师，化妆品专业的EC网站买家等。基于她在化妆品行业超过25年的经验...",
    },
  },
  {
    id: 7,
    name: "ペキ",
    name_lower: "peki",
    genre: ["skincare"],
    title: { ja: "", en: "", zh: "" },
    mid_profile: {
      ja: '<div class="profile_content"><p>21歳から、美容師を7年半、ヘアメイクを8年半ほど経験。</p><p>さまざまな領域のヘアメイクを学び、ヴィジュアル系バンドなどのメイクを得意とした。</p><p>現在は美容関連の会社員として働きつつ、重度の炎症に悩んだ経験などを活かし、肌が弱い方へ向けての情報を発信している。</p><div>',
      en: '<div class="profile_content"><p>From the age of 21, she has been a hairdresser for 7 and a half years and a hair and makeup artist for 8 and a half years.</p><p>She has learned hair and makeup in various fields and is good at makeup for visual bands.</p><p>She is currently working as a company employee in the beauty industry, and is disseminating information for those with weak skin based on her experience of suffering from severe inflammation.</p><div>',
      zh: '<div class="profile_content"><p>从21岁开始，她已经做了7年半的理发师和8年半的发型和化妆师。</p><p>她在各个领域学习了发型和化妆，擅长为视觉乐队化妆。</p><p>她目前在美容行业担任公司雇员，并根据她患有严重炎症的经验，向那些皮肤薄弱的人传播信息。</p><div>',
    },
    short_profile: {
      ja: "元美容師・ヘアメイク。現在は美容関連の会社員",
      en: "Former hairdresser and hair and makeup artist. Currently working as a company employee in the beauty industry",
      zh: "前理发师和发型和化妆师。目前在美容行业担任公司雇员",
    },
  },
];
